<template>
  <div
    id="stageline-sidebar-component"
    ref="stageline-sidebar"
  >
    <div
      id="stageline-sidebar-toggle"
      @click="toggleSidebar"
    >
      <double-arrow
        id="stageline-sidebar-double-arrow"
        :class="{ collapsed }"
      />
    </div>

    <stepline :collapsed="collapsed" />
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'StagelineSidebar',
  components: {
    Stepline:    () => import('./Stepline'),
    DoubleArrow: () => import('@images/ui/double-arrow.svg'),
  },
  data() {
    return {
      collapsed: false,
      windowWidth: window.innerWidth,
    }
  },
  watch: {
    windowWidth: _.debounce(function (newWidth, oldWidth) {
      const max = 1300
      if ((newWidth < oldWidth) && (newWidth < max)) {
        this.collapseSidebar()
      }
      if ((newWidth > oldWidth) && (newWidth >= max)) {
        this.expandSidebar()
      }
    }, 100, { leading: false, trailing: true }),
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    toggleSidebar() {
      if (this.collapsed) {
        this.expandSidebar()
      } else {
        this.collapseSidebar()
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth
    },
    collapseSidebar() {
      this.$refs['stageline-sidebar'].classList.add('collapsed')
      this.collapsed = true
    },
    expandSidebar() {
      this.$refs['stageline-sidebar'].classList.remove('collapsed')
      this.collapsed = false
    },
  },
}
</script>

<style scoped lang="scss">
#stageline-sidebar-component {
  width: 274px;
  background: $ct-ui-stageline-sidebar-background;
  border-right: 3px solid $ct-ui-color-21;
  min-height: calc(100vh - 60px);
  height: 100%;
  left: 0;
  position: relative;
  transition: all .1s linear;

  #stageline-sidebar-toggle {
    cursor: pointer;
    width: 28px;
    height: 28px;
    background: $ct-ui-stageline-sidebar-toggle-background;
    border-radius: 100%;
    box-shadow: $ct-ui-stageline-sidebar-toggle-box-shadow;
    position: absolute;
    right: -2px;
    top: 100px;
    transform: translateX(50%);
    display: flex;
    align-items: center;
    justify-content: center;

    #stageline-sidebar-double-arrow {
      height: 10px;
      width: 10px;
      fill: $ct-ui-color-17;
      transform: rotate(180deg);

      &.collapsed {
        transform: rotate(0deg);
      }
    }
  }

  &.collapsed {
    width: 80px;
  }
}

@media only screen and (max-width: 1024px) {
  #stageline-sidebar-component {

    #stageline-sidebar-toggle {
      top: 10%;
    }
  }
}

@media only screen and (max-width: 800px) {
  #stageline-sidebar-component {
    width: 100%;
    min-height: 28px;
    height: 100%;

    #stageline-sidebar-toggle {
      top: unset;
      bottom: 14px;
      right: calc(50% - 14px);
      transform: translateY(100%);

      #stageline-sidebar-double-arrow {
        transform: rotate(-90deg);

        &.collapsed {
          transform: rotate(90deg);
        }
      }
    }

    &.collapsed {
      width: 100%;
      height: 28px;
    }
  }
}
</style>
